import './app.scss';

import { inview } from './js/inview';

function start() {
    setTimeout(() => {
        const bodyEl = document.querySelector('body');
        const preloaderEl = document.getElementById('preloader');

        preloaderEl.classList.add('hide');
        bodyEl.style.height = '100%';
        bodyEl.style.overflowY = 'auto';

        setTimeout(() => {
            preloaderEl.remove();
        }, 500);
    }, 2500);

    if (inview) console.log('animate started');
}

start();
